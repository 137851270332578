export default {
  methods: {
    // hasPer: (nameOfRole) => {
    //   return JSON.parse(localStorage.getItem('my-roles')).indexOf(nameOfRole) > -1
    // },
    hasPer: () => true,
    isType: () => true,
    isAdmin: () => true
  }
}
