import axios from 'axios'
import { core } from '@/config/pluginInit'
import { VUE_APP_SSO_LINK, VUE_APP_INSURANCE_LINK, VUE_APP_WEBSITE_LINK } from './config/constant'
export const apiHeader = () => {
  return {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken')) ? JSON.parse(localStorage.getItem('userToken')) : null}`,
    Accept: 'application/json',
    ContentType: 'application/json',
    'Accept-Language': 'ar',
    'Content-Language': 'ar'
  }
}
export const apiResponseCatch = (error) => {
  if (error?.response?.data?.errors) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.data.errors?.hasOwnProperty('translations')) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors.translations)) {
        core.showSnackbar('error', value)
      }
    } else {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
  } else if (error?.response?.data) {
    core.showSnackbar('error', error.response.data.message)
  }
  return Promise.reject(error)
}
export function singleSignOn () {
  const apiClient = axios.create({
    baseURL: VUE_APP_SSO_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
export function marketplace () {
  const apiClient = axios.create({
    baseURL: VUE_APP_INSURANCE_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
export function website () {
  const apiClient = axios.create({
    baseURL: VUE_APP_WEBSITE_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
