<template>

<b-form-group
  :label="label"
  :label-for="name"
  class="w-100"
>
  <validation-provider
    :name="name"
    :rules="validate"
    :bails="false"
    #default="{
      errors
    }"
  >
    <div class="country-code d-flex align-items-center">
      <div class="country-code-input flex-fill">
        <input
          :id="id"
          :disabled="disabled"
          :type="type"
          :class="['form-control bg-white' , { 'is-invalid': errors.length > 0 }]"
          :value="value"
          :placeholder="placeholder"
          @input="$emit('input', $event.target.value)"
        >
      </div>
      <div class="country-code-code">
        <div class="d-flex flex-column">
          <!-- v-model="code" -->
          <vue-country-code
            @onSelect="onSelect"
            :onlyCountries="['sa', 'eg']"
            :dropdownOptions="{ disabledDialCode: true }"
            :enabledCountryCode= true
            :defaultCountry="defaultCountry"
            class="testphone"
            />
        </div>
      </div>
    </div>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      default: '',
      type: String
    },
    name: {
      default: '',
      type: String
    },
    type: {
      default: 'text'
    },
    id: {
      default: '',
      type: String
    },
    validate: {
      default: '',
      required: false
    },
    value: {
      default: '',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    disabled: {
      default: false
    },
    defaultCountry: {
      type: String,
      default: 'sa'
    }
  },
  data () {
    return {}
  },
  methods: {
    onSelect ({ name, dialCode }) {
      const countryCode = {
        20: '002',
        966: '00966'
      }
      this.$emit('onSelect', countryCode[dialCode])
    }
  }
}
</script>

<style lang="scss" scoped>
.country-code {
  width: 100%;
  background-color: #fff;
  border: 1px solid #d7dbda;
  font-size: 14px;
  border-radius: 10px;
  .form-control {
    border: none;
    background: transparent !important;
    border-radius: 0 !important;
  }
  .testphone {
    border-radius: 0 !important;
    border: none !important;
    border-inline-start: 1px solid  #d7dbda !important;
    margin: 0;
  }
}
</style>
